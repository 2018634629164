import { RepositoryFactory } from "@/repositories/RepositoryFactory";

const UserRepository = RepositoryFactory.get("UserRepository");

export default {
  namespaced: true,
  state: {
    currentUser: {}
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    }
  },
  actions: {
    async currentUser(context) {
      await UserRepository.current().then((user) => {
        context.commit("setCurrentUser", user);
      });
    }
  },
  getters: {
    isInRole: (state) => (role) => state.currentUser !== "undefined" && state.currentUser.roles.some((r) => r === role)
  }
};
