import moment from "moment";
import { DateHelpers } from "@/helpers.js";
export default {
  bookingChangeoverTime: 12,

  availabilityTreshold: {
    warning: 4,
    danger: 0
  },

  turnoverDisplayMode: {
    arrivals: "arrivals",
    departures: "departures",
    stays: "stays"
  },

  quantityTypes: {
    item: "Item",
    night: "Night",
    personPerNight: "PersonPerNight",
    position: "Position"
  },

  bookingTypes: {
    shortStay: "ShortStay",
    shortStayNonMembers: "ShortStayNonMembers",
    longStay: "LongStay",
    stay: "Stay",
    shortStorage: "ShortStorage",
    longStorage: "LongStorage"
  },

  bookingStates: {
    canceled: "Canceled",
    confirmed: "Confirmed",
    reservation: "Reservation",
    booking: "Booking"
  },

  pitchTypes: {
    CampingPitch: "CampingPitch",
    StoragePitch: "StoragePitch"
  },

  paymentStates: {
    open: "Open",
    paid: "Completed",
    overdue: "Overdue"
  },

  //if booking == confirmed -> paymentStatus, othwerwise -> bookingStatus
  displayStates: {
    open: "Open",
    paid: "Completed",
    overdue: "Overdue",
    canceled: "Canceled",
    confirmed: "Confirmed",
    reservation: "Reservation",
    booking: "Booking"
  },

  products: {
    skuPrefixes: {
      guests: {
        adult: "GST-VOLW",
        kids: "GST-KIND"
      },
      visitors: {
        all: "GST-LOGE"
      }
    }
  },

  defaultShortStayDateRange: {
    startDate: moment(DateHelpers.getNextDayInWeek(5)).toDate(),
    endDate: moment(DateHelpers.getNextDayInWeek(5)).add({ days: 3 }).toDate()
  },
  defaultLongStayDateRange: {
    startDate: moment().month(3).date(1).toDate(),
    endDate: moment().month(9).date(31).toDate()
  },
  defaultStorageDateRange: {
    startDate: moment().toDate(),
    endDate: moment().endOf("month").toDate()
  },
  defaultBookingSearchDateRange: {
    startDate: moment().add({ year: -1 }).month(3).date(1).toDate(),
    endDate: moment().add({ year: 1 }).month(10).date(31).toDate()
  },
  initialBookingSearchDateRange: {
    startDate: new Date(2020, 1, 1),
    endDate: moment().add({ year: 1 }).month(10).date(31).toDate()
  }
};
