import Axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshToken, getCurrentToken } from "@/services/UserTokenService";

const apiUrl = process.env.VUE_APP_API_ROOT;

const api = Axios.create({
  baseURL: apiUrl
});

createAuthRefreshInterceptor(api, refreshToken);

api.interceptors.request.use(function (config) {
  let token = getCurrentToken();
  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export { api };
