import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/catalog";

export default {
  getProducts(catalogId, bookingTypeQueryType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/products`, {
      params: {
        bookingTypeQueryType: bookingTypeQueryType
      }
    });
  }
};
