import { api as AxiosRepository } from "@/repositories/AxiosRepository";
const apiVersionBaseUrl = "/v1/users";

export default {
  async current() {
    return AxiosRepository.get(`${apiVersionBaseUrl}/current`).then((response) => response.data);
  },
  async passwordReset(username) {
    return AxiosRepository.post(`${apiVersionBaseUrl}/password/reset`, { username: username }).then((response) => response.data);
  },
  async passwordChange(password, token) {
    return AxiosRepository.put(`${apiVersionBaseUrl}/password/change`, { password: password, token: token }).then((response) => response.data);
  }
};
