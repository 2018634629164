import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import _ from "lodash";

const PaymentMethodRepository = RepositoryFactory.get("PaymentMethodRepository");

export default {
  namespaced: true,
  state: {
    paymentMethods: []
  },
  getters: {
    getById: (state) => (id) =>
      _.find(state.paymentMethods, function (p) {
        return p.id == id;
      })
  },
  mutations: {
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    }
  },
  actions: {
    async fetchPaymentMethods(context) {
      await PaymentMethodRepository.getPaymentMethods().then((paymentMethods) => {
        context.commit("setPaymentMethods", paymentMethods);
      });
    }
  }
};
