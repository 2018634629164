import { api } from "@/repositories/AxiosRepository";

export function refreshToken(failedRequest) {
  let data = new URLSearchParams();

  data.append("grant_type", "refresh_token");
  data.append("refresh_token", getCurrentRefreshToken());

  return api
    .post(`auth/token`, data, {
      headers: {
        Accept: "application/json"
      }
    })
    .then((tokenRefreshResponse) => {
      let data = tokenRefreshResponse.data;
      setToken(data.access_token, data.refresh_token, data.expires_in);
      failedRequest.response.config.headers["Authorization"] = `Bearer ${data.access_token}`;
      return Promise.resolve();
    })
    .catch((error) => {
      if (error.response.status === 401) {
        clearToken();
        location.reload();
      }
    });
}

export function getCurrentToken() {
  return localStorage.getItem("token");
}

export function getCurrentRefreshToken() {
  return localStorage.getItem("refreshToken");
}

export function setToken(token, refreshToken, expiresInSeconds) {
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("tokenExpiresAt", Math.floor(Date.now() / 1000) + expiresInSeconds);
}

export function clearToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenExpiresAt");
}
