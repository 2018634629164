import { api } from "@/repositories/AxiosRepository";
const apiVersionBaseUrl = "/auth";
export default {
  async login(username, password) {
    let data = new URLSearchParams();

    data.append("grant_type", "password");
    data.append("username", username);
    data.append("password", password);

    return api.post(`${apiVersionBaseUrl}/token`, data, {
      headers: {
        Accept: "application/json"
      }
    });
  }
};
