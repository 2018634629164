import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/reports";

export default {
  getTouristTax(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/touristtax`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    });
  },
  downloadTouristTax(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/touristtax/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  getPayments(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/payments`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    });
  },
  downloadPayments(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/payments/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  }
};
