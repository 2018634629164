import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/catalog";

//TODO: validate async/await pattern vs return response.data

export default {
  index(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    });
  },
  getByQuery(catalogId, query, range, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings`, {
      params: {
        query: query,
        from: range.from,
        to: range.to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  getByStatus(catalogId, from, to, status, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/status/${status}`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  downloadByStatus(catalogId, from, to, status, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/status/${status}/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  getCreated(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/created`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  getModified(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/modified`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  getCanceled(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/canceled`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  getArrivals(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/arrivals`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  downloadArrivals(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/arrivals/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  getDepartures(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/departures`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  downloadDepartures(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/departures/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  getStays(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/stays`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  downloadStays(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/stays/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  getStorage(catalogId, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/storage`, {
      params: {
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    }).then((response) => response.data);
  },
  downloadStorage(catalogId, from, to, fileType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/storage/download/${fileType}`, {
      params: {
        from: from,
        to: to
      },
      responseType: "blob"
    });
  },
  get(catalogId, id) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}`, {}).then((response) => response.data);
  },
  create(catalogId, booking) {
    return AxiosRepository.post(`${apiVersionBaseUrl}/${catalogId}/bookings`, booking).then((response) => response.data);
  },
  async checkIn(catalogId, id, date) {
    await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/checkin`, { date: date.toISOString() });
  },
  async checkOut(catalogId, id, date) {
    await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/checkout`, { date: date.toISOString() });
  },
  async undoCheckIn(catalogId, id) {
    await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/checkin`);
  },
  async undoCheckOut(catalogId, id) {
    await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/checkout`);
  },
  createComment(catalogId, id, comment) {
    return AxiosRepository.post(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/comments`, comment);
  },
  setStatus(catalogId, id, status) {
    return AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/status`, { status: status });
  },
  async assignPitches(catalogId, id, pitches) {
    return await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/pitches`, pitches);
  },
  async assignPitch(catalogId, id, pitch) {
    return await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/pitch`, pitch);
  },
  async unassignPitch(catalogId, id) {
    return await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/pitch`);
  },
  async updateDateRange(catalogId, id, newDateRange) {
    return await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/daterange`, newDateRange);
  },
  async deleteBooking(catalogId, id) {
    return await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}`);
  }
};
