<template>
  <b-modal
    id="create-booking"
    ref="createBooking"
    v-model="modalShow"
    size="lg"
    :title="modalTitle"
    :ok-title="modalStepIndex < 1 ? 'Volgende' : 'Boeking aanmaken'"
    cancel-title="Annuleren"
    ok-variant="success"
    cancel-variant="light"
    :ok-disabled="!modalOkButtonEnabled"
    @ok.prevent="onOkCreateClicked"
  >
    <div>
      <div v-if="modalStepIndex === 0">
        <b-tabs v-model="memberAddressTabIndex" content-class="mt-3">
          <b-tab title="Lid" active @click="resetMemberBooking(true)">
            <b-form-row>
              <b-form-group label="Zoek een lid" :label-cols="3" class="col-sm-12">
                <auto-suggest-member placeholder="Achternaam, lidnummer of postcode" @select="onMemberSelected($event)" />
              </b-form-group>
            </b-form-row>
            <div v-if="booking.memberId">
              <new-booking-address-member :member="member" />
            </div>
          </b-tab>
          <b-tab v-if="bookingType == config.bookingTypes.shortStay" title="Niet-Lid" @click="resetMemberBooking(false)">
            <new-booking-address-form ref="newBookingAddressForm" :booking="booking" />
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="modalStepIndex === 1">
        <validation-observer ref="createBookingDetailsForm" v-slot="{ passes }">
          <b-form @submit.stop.prevent="passes(createBooking)">
            <b-form-row>
              <b-form-group :label="$t('accommodation.accommodation')" :label-cols="3" class="col-sm-12">
                <ValidationProvider v-slot="v" :name="$t('accommodation.accommodation')" rules="required|min:2">
                  <b-form-select
                    :options="accommodations"
                    :value="booking.accommodationSkus[0]"
                    text-field="name"
                    value-field="productSku"
                    class="mb-3"
                    name="accommodation"
                    :state="getValidationState(v)"
                    @change="
                      onItemUpdated('accommodationSkus', $event);
                      onAccommodationChanged($event);
                    "
                  />
                  <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
                  <b-alert variant="warning" :show="!hasSubscription" class="mt-3">{{
                    $t("booking.not_subscribed", {
                      memberName: "",
                      productName: ""
                    })
                  }}</b-alert>
                </ValidationProvider>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="Periode" :label-cols="3" class="col-sm-12">
                <v-date-picker mode="range" is-required locale="nl" :value="{ start: booking.startDate, end: booking.endDate }" :columns="2" @input="onDateRangeChanged($event)">
                  <b-input-group slot-scope="{ inputValue, updateValue }" class="date-picker">
                    <b-input-group-text slot="prepend">
                      <i class="far fa-calendar-alt" />
                    </b-input-group-text>
                    <b-form-input :class="[{ 'show-day-span': true }]" :value="inputValue" @change.native="updateValue($event.target.value)" />
                    <b-input-group-text slot="append" class="date-range">{{ accommodationDaysSpan }} nachten</b-input-group-text>
                  </b-input-group>
                </v-date-picker>
                <b-alert variant="danger" :show="availablePitches <= config.availabilityTreshold.danger" class="mt-3">{{ $t("booking.no_availability") }}</b-alert>
                <b-alert variant="warning" :show="availablePitches <= config.availabilityTreshold.warning && availablePitches > 0" class="mt-3">{{
                  $t("booking.low_availability", { availablePitches })
                }}</b-alert>
              </b-form-group>
            </b-form-row>
            <b-form-row v-if="bookingType == config.bookingTypes.shortStay">
              <b-form-group :label="$t('guest.guests')" :label-cols="3" class="col-sm-12">
                <b-form-row>
                  <b-col v-for="product in guestsBySku(config.products.skuPrefixes.guests.adult)" :key="product.sku" cols="4" class="form-group col-sm-6">
                    <label>{{ product.name }}</label>
                    <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
                  </b-col>
                  <b-col v-for="product in guestsBySku(config.products.skuPrefixes.guests.kids)" :key="product.productSku" cols="4" class="form-group col-sm-6">
                    <label>{{ product.name }}</label>
                    <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-form-row>
            <b-form-row v-if="bookingType == config.bookingTypes.shortStay && guestsBySku(config.products.skuPrefixes.visitors.all).length">
              <b-form-group label="Logees" :label-cols="3" class="col-sm-12">
                <b-form-row>
                  <b-col v-for="product in guestsBySku(config.products.skuPrefixes.visitors.all)" :key="product.productSku" cols="4" class="form-group col-sm-6">
                    <label>{{ product.name }}</label>
                    <input-spinner integer :value="numberOfGuests(product.productSku)" :min="0" :max="9" @input="onGuestCountChanged(product.productSku, $event)" />
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-form-row>
            <b-form-row v-if="bookingType == config.bookingTypes.shortStay || bookingType == config.bookingTypes.longStay">
              <b-form-group label="Toeristenbelasting" :label-cols="3" class="col-sm-12">
                <b-form-select
                  :options="touristTaxes"
                  :value="booking.touristTaxSkus[0] || null"
                  text-field="name"
                  value-field="productSku"
                  class="mb-3"
                  name="touristTax"
                  @change="onItemUpdated('touristTaxSkus', $event)"
                >
                  <template #first>
                    <option :value="null">{{ $t("tourist_tax.empty") }}</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-row>
            <b-form-row v-if="!isMemberBooking">
              <b-form-group :label="$t('electricity.electricity')" :label-cols="3" class="col-sm-12">
                <b-form-select
                  :options="electricities"
                  :value="booking.electricitySkus[0] || null"
                  text-field="name"
                  value-field="productSku"
                  class="mb-3"
                  name="electricity"
                  @change="onItemUpdated('electricitySkus', $event)"
                >
                  <template #first>
                    <option :value="null">{{ $t("electricity.empty") }}</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped></style>

<script>
import config from "@/config";
import { mapActions, mapState } from "vuex";
import { DateHelpers } from "@/helpers.js";

import ValidationMixin from "@/mixins/validation-mixin";
import AutoSuggestMember from "@/components/common/AutoSuggest/AutoSuggestMember";
import InputSpinner from "@/components/common/InputSpinner.vue";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");
const MemberRepository = RepositoryFactory.get("MemberRepository");
const ProductRepository = RepositoryFactory.get("ProductRepository");
const MetricRepository = RepositoryFactory.get("MetricRepository");

import NewBookingAddressMember from "@/components/common/Dialogs/partials/NewBookingAddressMember";
import NewBookingAddressForm from "@/components/common/Dialogs/partials/NewBookingAddressForm";

export default {
  components: {
    NewBookingAddressForm,
    NewBookingAddressMember,
    AutoSuggestMember,
    InputSpinner
  },
  mixins: [ValidationMixin],

  props: {
    bookingType: { type: String, default: config.bookingTypes.shortStay }
  },

  data() {
    return {
      config: config,
      modalShow: false,
      modalStepIndex: 0,
      memberAddressTabIndex: 0,

      editBookingAddress: false,
      availablePitches: 100, //initially set to any number > 3 to avoid messages being shown
      hasSubscription: true,

      products: [],
      accommodations: [],
      touristTaxes: [],
      electricities: [],

      member: {},

      booking: {}
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"]),

    modalTitle: function () {
      if (this.bookingType == config.bookingTypes.shortStay) return "Nieuwe boeking voor een kort verblijf";
      if (this.bookingType == config.bookingTypes.longStay) return "Nieuwe boeking voor een lang verblijf";

      return "Nieuwe boeking voor een stalling";
    },
    modalOkButtonEnabled: function () {
      if (this.modalStepIndex === 0 && this.memberAddressTabIndex === 0) {
        // only return true when a member has been selected
        return this.booking.memberId;
      } else if (this.modalStepIndex === 0 && this.memberAddressTabIndex === 1) {
        // the validator is evaluated in the onOkCreateClicked
        return true;
      }

      //we're in step 2 and we can always continue, regardless if there are enough pitches available
      return true; //this.availablePitches > 0;

      // if (this.modalStepIndex === 0) {
      //   if (this.booking.memberId) {
      //     return false;
      //   } else if (this.memberAddressTabIndex === 0) {
      //     return false;
      //   }
      //   return true;
      // } else if (this.modalStepIndex === 1) {
      //   return this.availablePitches == 0;
      // }
    },
    isMemberBooking: function () {
      return this.booking.memberId != null;
    },
    accommodationDaysSpan: function () {
      if (this.booking.startDate && this.booking.endDate) {
        return DateHelpers.getDaysSpan({
          start: this.booking.startDate,
          end: this.booking.endDate
        });
      }
      return null;
    }
  },

  watch: {
    modalShow: function (show) {
      if (show) {
        this.reset();
      }
    }
  },

  created() {
    this.reset();
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),

    reset() {
      this.editBookingAddress = false;
      this.memberAddressTabIndex = 0;
      this.modalStepIndex = 0;
      this.member = {};
      this.booking = {
        startDate: this.$moment().toDate(),
        endDate: this.$moment().toDate(),
        bookingAddress: {},
        accommodationSkus: [],
        guestSkus: [],
        electricitySkus: [],
        touristTaxSkus: [],
        otherProductSkus: [],
        memberId: null,
        allianceNumber: null,
        allianceMemberNumber: null
      };
    },
    resetMemberBooking: function (isMemberBooking) {
      if (isMemberBooking && !this.booking.memberId) {
        this.member = {};
        this.booking.allianceNumber = null;
        this.booking.allianceMemberNumber = null;
        this.booking.bookingAddress = {};
        this.booking.memberId = null;
      } else if (!isMemberBooking && this.booking.memberId) {
        this.member = {};
        this.booking.bookingAddress = {};
        this.booking.memberId = null;
      }
    },

    numberOfGuests: function (sku) {
      return this.booking.guestSkus.filter((guestSku) => guestSku === sku).length;
    },
    guestsBySku: function (sku) {
      return this.products.filter((p) => p.productDefinition === "Guest" && p.productSku.startsWith(sku));
    },

    onOkCreateClicked: function () {
      if (this.modalStepIndex === 0) {
        //when we're in step 1

        if (this.booking.memberId) {
          this.modalStepIndex = 1;
        } else {
          this.$refs.newBookingAddressForm.$refs.addressForm.validate().then((result) => {
            if (!result) return;

            this.modalStepIndex = 1;
          });
        }

        this.fetchPitchAvailability(
          this.booking.startDate,
          this.booking.endDate,
          this.bookingType == config.bookingTypes.longStorage ? config.pitchTypes.StoragePitch : config.pitchTypes.CampingPitch
        );
        this.fetchProducts();
      } else {
        //we're in step 2

        this.createBooking();
      }
    },

    onMemberSelected: function (selected) {
      this.editBookingAddress = false;
      this.booking.memberId = selected.id;

      MemberRepository.getById(selected.id)
        .then((response) => {
          this.member = response.data;

          this.booking.bookingAddress = {
            name: response.data.name,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            address1: response.data.address1,
            address2: response.data.address2,
            postcode: response.data.postcode,
            city: response.data.city,
            country: response.data.country,
            countryCode: response.data.countryCode,
            phone1: response.data.phone1,
            phone2: response.data.phone2,
            emailAddress: response.data.emailAddress
          };

          this.booking.registrationPlate = response.data.registrationPlate;
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },

    onItemUpdated: function (item, value) {
      if (value !== null) {
        this.booking[item] = [value];
      } else {
        this.booking[item] = [];
      }
    },
    onAccommodationChanged: function (sku) {
      let item = this.$_.find(this.accommodations, { productSku: sku });
      if (!item) return;

      if (this.booking.memberId > 0) {
        MemberRepository.getSubscription(this.booking.memberId, item.productSku)
          .then((response) => {
            this.hasSubscription = response.data.subscribed;
          })
          .catch((error) => {
            this.$notify({
              group: "app",
              type: "error",
              title: this.$i18n.t("general_error_title"),
              text: error.response.data.message
            });
          });
      }

      if ((this.bookingType == config.bookingTypes.longStay || this.bookingType == config.bookingTypes.longStorage) && item.startDate && item.endDate) {
        this.onDateRangeChanged({
          start: item.startDate,
          end: item.endDate
        });
      }
    },
    onGuestCountChanged: function (sku, count) {
      while (this.numberOfGuests(sku) > count) {
        let guestIndex = this.booking.guestSkus.findIndex((guestSku) => guestSku === sku);
        this.booking.guestSkus.splice(guestIndex, 1);
      }

      while (this.numberOfGuests(sku) < count) {
        this.booking.guestSkus.push(sku);
      }
    },

    onDateRangeChanged: function (range) {
      //NOTE: when creating the booking (on Aanmaken) the method is called with an empty range (why?)
      //if (!range) return;

      this.booking.startDate = range.start;
      this.booking.endDate = range.end;

      //validate availability
      this.fetchPitchAvailability(range.start, range.end, this.bookingType == config.bookingTypes.longStorage ? config.pitchTypes.StoragePitch : config.pitchTypes.CampingPitch);
    },

    createBooking() {
      this.$refs.createBookingDetailsForm.validate().then((success) => {
        if (success) {
          BookingRepository.create(this.currentCampsite.id, this.booking)
            .then((response) => {
              if (response) {
                this.$refs.createBooking.hide();
                this.$router.push({
                  name: "booking-detail",
                  params: { id: response }
                });
              }
            })
            .catch((error) => {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response.data.message
              });
            });
        }
      });
    },
    async fetchProducts() {
      let bookingTypeQueryType = this.bookingType;
      if (this.bookingType == config.bookingTypes.shortStay && this.booking.memberId === null) bookingTypeQueryType = config.bookingTypes.shortStayNonMembers;

      await ProductRepository.getProducts(this.currentCampsite.id, bookingTypeQueryType)
        .then((response) => {
          let products = [];
          response.data.forEach((p) => {
            if (p.variants.length === 0) {
              products.push(p);
            } else {
              products = products.concat(p.variants);
            }
          });

          this.products = products.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
          this.accommodations = this.products.filter((p) => p.productDefinition === this.bookingType);
          this.touristTaxes = this.products.filter((p) => p.productDefinition === "TouristTax");
          this.electricities = this.products.filter((p) => p.productDefinition === "Electricity");
          this.otherProducts = this.products.filter((p) => p.productDefinition === "Product");
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });

      this.setBookingDefaults();
    },
    fetchPitchAvailability: function (startDate, endDate, pitchType) {
      if (!startDate || !endDate) return;

      MetricRepository.getAvailability(this.currentCampsite.id, startDate, endDate, pitchType)
        .then((response) => {
          let availability = response.data.dimensions.find((x) => x.name === "Available").value;
          this.availablePitches = parseInt(availability, 10);
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    setBookingDefaults() {
      if (this.bookingType === config.bookingTypes.shortStay) {
        this.onDateRangeChanged({
          start: config.defaultShortStayDateRange.startDate,
          end: config.defaultShortStayDateRange.endDate
        });
      } else if (this.bookingType === config.bookingTypes.shortStorage) {
        this.onDateRangeChanged({
          start: config.defaultStorageDateRange.startDate,
          end: config.defaultStorageDateRange.endDate
        });
      }

      if (this.accommodations.length > 0) {
        //let item = this.accommodations[0];
        let item = DateHelpers.getFirstInRangeForDate(this.accommodations, Date.now()) || this.accommodations[0];

        //TODO: refactor line below
        this.booking["accommodationSkus"] = [item.productSku];

        this.onAccommodationChanged(item.productSku);
      }

      if (this.bookingType == config.bookingTypes.shortStay) {
        this.onGuestCountChanged(this.config.products.skuPrefixes.guests.adult, 2);
      }

      if (this.bookingType == config.bookingTypes.shortStay && this.touristTaxes.length) {
        let item = DateHelpers.getFirstInRangeForDate(this.touristTaxes, Date.now()) || this.touristTaxes[0];
        //let item = this.touristTaxes[0];
        this.onItemUpdated("touristTaxSkus", item.productSku);
      }

      if (this.bookingType == config.bookingTypes.shortStay && this.electricities.length) {
        if (this.member.id) {
          //NOTE: the line below is temporarily commented out to avoid that ELC is automatically added to a member's booking
          //      while we do not have a solution in place to visually indicate that the new startPosition has been updated in the meantime
          this.onItemUpdated("electricitySkus", null);
        } else {
          let item = DateHelpers.getFirstInRangeForDate(this.electricities, Date.now()) || this.electricities[0];
          this.onItemUpdated("electricitySkus", item.productSku);
        }
      }
    }
  }
};
</script>
