//import { first } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

export const DateHelpers = {
  getDaysSpan(range) {
    return moment(range.end).diff(moment(range.start), "days");
  },
  getDaysCount(dates) {
    if (!dates) return 0;
    return dates.length;
  },
  getDatesFromDateRange(range) {
    var currDate = moment(range.start).startOf("day").hour(0);
    var lastDate = moment(range.end).startOf("day").hour(0);

    var dates = [];

    while (currDate.diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
      currDate.add({ days: 1 });
    }

    return dates;
  },
  spansMultipleMonths(dates) {
    if (dates === null) return false;
    return dates.map((d) => new Date(d).getMonth()).filter((v, i, a) => a.indexOf(v) === i).length > 1;
  },
  getFirstInRangeForDate(items, date) {
    const sorted = items.sort(function (a, b) {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

    return sorted.find((item) => new Date(item.startDate).getTime() < new Date(date).getTime());
  },
  getNextDayInWeek(nextDay) {
    if (moment().isoWeekday() <= nextDay) return moment().isoWeekday(nextDay).toDate();
    else return moment().add({ weeks: 1 }).isoWeekday(nextDay).toDate();
  },
  getOverlapping(arrayDateObjects) {
    let ranges = arrayDateObjects.map((d) => moment.range(d.s, d.e));
    return ranges.find((range, index) => ranges.some((candidate, candidateIndex) => index !== candidateIndex && candidate.overlaps(range)));
  },
  getNextDateRange(range) {
    var diff = moment(range.endDate).diff(moment(range.startDate), "days");
    var startDate = moment(range.startDate).add({ days: diff + 1 });
    var endDate = moment(range.endDate).add({ days: diff + 1 });

    //if we're at the start/enddate of the month, move a month forward
    if (moment(range.startDate).startOf("month").isSame(range.startDate) && moment(range.endDate).endOf("month").isSame(range.endDate)) {
      startDate = moment(range.startDate).add({ months: 1 }).startOf("month");
      endDate = moment(range.endDate).add({ months: 1 }).endOf("month");
    }

    return { startDate: startDate.toDate(), endDate: endDate.toDate() };
  },
  getPreviousDateRange(range) {
    var diff = moment(range.endDate).diff(moment(range.startDate), "days");
    var startDate = moment(range.startDate).subtract({ days: diff + 1 });
    var endDate = moment(range.endDate).subtract({ days: diff + 1 });

    //if we're at the start/enddate of the month, move a month backward
    if (moment(range.startDate).startOf("month").isSame(range.startDate) && moment(range.endDate).endOf("month").isSame(range.endDate)) {
      startDate = moment(range.startDate).subtract({ months: 1 }).startOf("month");
      endDate = moment(range.endDate).subtract({ months: 1 }).endOf("month");
    }

    return { startDate: startDate.toDate(), endDate: endDate.toDate() };
  }
};
