import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/catalog";

export default {
  async create(catalogId, bookingId, stay) {
    return AxiosRepository.post(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays`, stay).then((response) => response.data);
  },
  async updateDateRange(catalogId, bookingId, stayId, startDate, endDate) {
    await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/dateRange`, { startDate, endDate });
  },
  async updateAccommodations(catalogId, bookingId, stayId, accommodations) {
    await AxiosRepository.patch(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/accommodations`, accommodations);
  },
  async updateGuests(catalogId, bookingId, stayId, guests) {
    await AxiosRepository.patch(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/guests`, guests);
  },
  async updateTouristTaxes(catalogId, bookingId, stayId, touristTaxes) {
    await AxiosRepository.patch(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/touristTaxes`, touristTaxes);
  },
  async updateElectricities(catalogId, bookingId, stayId, electricities) {
    await AxiosRepository.patch(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/electricities`, electricities);
  },
  async updateOtherProducts(catalogId, bookingId, stayId, products) {
    await AxiosRepository.patch(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/otherProducts`, products);
  },
  async createPayment(catalogId, bookingId, stayId, payment) {
    await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/payments`, payment);
  },
  async addVoucher(catalogId, bookingId, stayId, voucher) {
    await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/vouchers`, voucher);
  },
  async removeVoucher(catalogId, bookingId, stayId, discountId) {
    await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/vouchers`, {
      params: { discountId: discountId }
    });
  },
  async deletePayment(catalogId, bookingId, stayId, paymentId) {
    await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}/payments`, {
      params: { paymentId: paymentId }
    });
  },
  async delete(catalogId, bookingId, stayId) {
    await AxiosRepository.delete(`${apiVersionBaseUrl}/${catalogId}/bookings/${bookingId}/stays/${stayId}`);
  },
  async calculateStay(catalogId, id, stayId) {
    return await AxiosRepository.put(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/stays/${stayId}/calculate`);
  },
  async downloadInvoice(catalogId, id, stayId) {
    return await AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/stays/${stayId}/invoice`, { responseType: "blob" });
  },
  async sendInvoice(catalogId, id, stayId, emailAddress) {
    await AxiosRepository.post(`${apiVersionBaseUrl}/${catalogId}/bookings/${id}/stays/${stayId}/invoice/send`, { emailAddress });
  }
};
