import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/countries";

export default {
  getAll() {
    return AxiosRepository.get(apiVersionBaseUrl, {
      params: {}
    }).then((response) => response.data);
  },
  getByQuery(query) {
    return AxiosRepository.get(apiVersionBaseUrl, {
      params: {
        query
      }
    }).then((response) => response.data);
  }
};
