import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import _ from "lodash";

const CampsiteRepository = RepositoryFactory.get("CampsiteRepository");

export default {
  namespaced: true,
  state: {
    currentCampsite: null,
    campsites: []
  },
  getters: {
    getById: (state) => (id) => _.find(state.campsites, { id: id })
  },
  mutations: {
    setCurrentCampsite(state, campsite) {
      state.currentCampsite = campsite;
      localStorage.setItem("currentCampsiteId", campsite.id);
    },
    setCampsites(state, campsites) {
      state.campsites = campsites;
    }
  },
  actions: {
    async fetchCampsites(context) {
      await CampsiteRepository.getCampsites().then((campsites) => {
        context.commit("setCampsites", campsites);

        if (context.state.currentCampsite === null) {
          let campsite = campsites[0];

          const currentCampsiteId = localStorage.getItem("currentCampsiteId");
          if (currentCampsiteId) {
            const currentCampsite = context.getters.getById(parseInt(currentCampsiteId));
            if (currentCampsite) {
              campsite = currentCampsite;
            }
          }

          localStorage.setItem("currentCampsiteId", campsite.id);
          context.commit("setCurrentCampsite", campsite);
        }
      });
    }
  }
};
