import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/catalog";

export default {
  getPitches(catalogId, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/pitches`, {
      params: {
        offset: offset,
        limit: limit
      }
    });
  },
  getPitchBookings(catalogId, pitchType, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/pitches/bookings`, {
      params: {
        type: pitchType,
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    });
  },
  getBookingsWithPitch(catalogId, pitchType, from, to, offset, limit) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/pitches/bookingswithpitch`, {
      params: {
        type: pitchType,
        from: from,
        to: to,
        offset: offset,
        limit: limit
      }
    });
  }
};
