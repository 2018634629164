<template>
  <b-row v-if="currentCampsite" class="flex-xl-nowrap">
    <b-col cols="2" class="bg-navyblue px-0 vh-100 position-fixed">
      <div class="bg-navyblue p-4">
        <user-avatar />
      </div>

      <side-navigation />
      <div class="mb pl-4">
        <router-link class="logout text-white" :to="{ name: 'login' }"> <i class="fas fa-sign-out-alt"></i> Uitloggen </router-link>
      </div>
    </b-col>
    <b-col cols="10" offset="2">
      <!-- <div class="h-100 d-flex flex-column"> -->
      <header-navigation />

      <router-view :key="$route.fullPath" name="content" class="px-3" />
      <!-- </div> -->
    </b-col>

    <notifications style="margin-top: 10px" position="top right" :speed="500" :duration="10000" group="app"></notifications>
  </b-row>
</template>

<style lang="scss">
.logout {
  position: absolute;
  bottom: 25px;
}
</style>

<script>
import { mapState } from "vuex";
import SideNavigation from "@/components/SideNavigation.vue";
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import UserAvatar from "@/components/UserAvatar";

export default {
  components: { SideNavigation, HeaderNavigation, UserAvatar },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  beforeCreate() {
    this.$store.dispatch("Campsites/fetchCampsites");
    this.$store.dispatch("Countries/fetchCountries");
    this.$store.dispatch("Alliances/fetchAlliances");
    this.$store.dispatch("PaymentMethods/fetchPaymentMethods");
    this.$store.dispatch("Users/currentUser");
  },

  mounted() {
    if (localStorage.currentCampsiteId) {
      this.name = localStorage.name;
    }
  }
};
</script>
