import AuthenticationRepository from "@/repositories/AuthenticationRepository";
import AllianceRepository from "@/repositories/AllianceRepository";
import BookingRepository from "@/repositories/BookingRepository";
import CampsiteRepository from "@/repositories/CampsiteRepository";
import CountryRepository from "@/repositories/CountryRepository";
import MemberRepository from "@/repositories/MemberRepository";
import MetricRepository from "@/repositories/MetricRepository";
import PaymentMethodRepository from "@/repositories/PaymentMethodRepository";
import PitchRepository from "@/repositories/PitchRepository";
import ProductRepository from "@/repositories/ProductRepository";
import ReportRepository from "@/repositories/ReportRepository";
import StayRepository from "@/repositories/StayRepository";
import UserRepository from "@/repositories/UserRepository";

const repositories = {
  AuthenticationRepository,
  AllianceRepository,
  BookingRepository,
  CampsiteRepository,
  CountryRepository,
  MemberRepository,
  MetricRepository,
  PaymentMethodRepository,
  PitchRepository,
  ProductRepository,
  ReportRepository,
  StayRepository,
  UserRepository
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
