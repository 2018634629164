<template>
  <vue-autosuggest
    ref="autosuggest"
    class="autosuggest"
    :suggestions="suggestions"
    :input-props="{ class: 'autosuggest__input form-control', placeholder: placeholder, onInputChange: onChanged }"
    :section-configs="{
      members: {
        limit: 12,
        label: 'Leden',
        onSelected: (suggestion) => {
          onSelected(suggestion);
        }
      }
    }"
    @selected="onSelected"
  >
    <template slot-scope="{ suggestion }">
      <div class="row no-gutters">
        <div class="col pr-3">
          <strong>{{ suggestion.item.name }}</strong
          >&nbsp;<span class="badge badge-info">{{ suggestion.item.memberNumber }}</span
          ><br />
          <small class="text-muted">{{ [suggestion.item.address1, suggestion.item.address2, suggestion.item.postcode, suggestion.item.city].filter((x) => x).join() }}</small>
        </div>
        <div class="col">
          <small class="text-muted">{{ suggestion.item.emailaddress }}</small
          ><br />
          <small class="text-muted">{{ suggestion.item.phone }}</small>
        </div>
      </div>
    </template>
  </vue-autosuggest>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const MemberRepository = RepositoryFactory.get("MemberRepository");

export default {
  props: {
    debounceMilliseconds: { type: Number, default: 500 },
    debounceStringLength: { type: Number, default: 2 },
    placeholder: { type: String, default: null }
  },
  data() {
    return {
      suggestions: [],
      timeout: null
    };
  },
  methods: {
    onChanged(value) {
      if (value && value.length >= this.debounceStringLength) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          MemberRepository.getByQuery(value).then((members) => {
            this.suggestions = [{ name: "members", data: members.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)) }];
          });
        }, this.debounceMilliseconds);
      }
    },
    onSelected(suggestion) {
      if (suggestion.item !== undefined) this.$emit("select", suggestion.item);

      //clear input
      this.$refs.autosuggest.searchInput = "";

      //clear suggestions
      this.suggestions = [];
    }
  }
};
</script>
