import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import _ from "lodash";

const CountryRepository = RepositoryFactory.get("CountryRepository");

export default {
  namespaced: true,
  state: {
    countries: []
  },
  getters: {},
  mutations: {
    setCountries(state, countries) {
      state.countries = _.sortBy(countries, ["name"]);
    }
  },
  actions: {
    async fetchCountries(context) {
      await CountryRepository.getAll().then((countries) => {
        context.commit("setCountries", countries);
      });
    }
  }
};
