import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      components: {
        default: () => import("@/components/Layout.vue")
      },
      children: [
        {
          path: "",
          name: "dashboard",
          components: {
            content: () => import("@/components/dashboard/Dashboard.vue")
          }
        },
        {
          path: "/profile",
          name: "profile",
          components: {
            content: () => import("@/components/user/Profile.vue")
          }
        },
        {
          path: "/bookings/list/confirmed",
          name: "bookings-confirmed",
          components: {
            content: () => import("@/components/bookings-list/Confirmed.vue")
          }
        },
        {
          path: "/bookings/list/reservation",
          name: "bookings-reservation",
          components: {
            content: () => import("@/components/bookings-list/Reservation.vue")
          }
        },
        {
          path: "/bookings/list/canceled",
          name: "bookings-canceled",
          components: {
            content: () => import("@/components/bookings-list/Canceled.vue")
          }
        },
        {
          path: "/bookings/calendar",
          name: "bookings-calendar",
          components: {
            content: () => import("@/components/bookings-calendar/Timeline.vue")
          }
        },
        {
          path: "/bookings/storage",
          name: "storage-calendar",
          components: {
            content: () => import("@/components/storage-calendar/Timeline.vue")
          }
        },
        {
          path: "/bookings/:id",
          name: "booking-detail",
          components: {
            content: () => import("@/components/booking-details/Booking.vue")
          }
        },
        {
          path: "/reports/arrivals",
          name: "arrivals",
          components: {
            content: () => import("@/components/reports/Arrivals.vue")
          }
        },
        {
          path: "/reports/departures",
          name: "departures",
          components: {
            content: () => import("@/components/reports/Departures.vue")
          }
        },
        {
          path: "/reports/stays",
          name: "stays",
          components: {
            content: () => import("@/components/reports/Stays.vue")
          }
        },
        {
          path: "/reports/storage",
          name: "storage",
          components: {
            content: () => import("@/components/reports/Storage.vue")
          }
        },
        {
          path: "/reports/touristtax",
          name: "touristtax",
          components: {
            content: () => import("@/components/reports/Touristtax.vue")
          }
        },
        {
          path: "/reports/payments",
          name: "payments",
          components: {
            content: () => import("@/components/reports/Payments.vue")
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/authentication/Login.vue")
    },
    {
      path: "/users/forgot-password",
      name: "users-forgot-password",
      component: () => import("@/components/authentication/ForgotPassword.vue")
    },
    {
      path: "/users/reset-password/:token",
      name: "users-reset-password",
      component: () => import("@/components/authentication/ResetPassword.vue")
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("@/components/common/errors/404.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const publicPagesRegex = /\/(login)|(users\/(forgot-password|reset-password\/.*))/g;
  const authRequired = to.path.match(publicPagesRegex) || [];

  const loggedIn = localStorage.getItem("token");

  if (authRequired.length === 0 && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
