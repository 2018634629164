<template>
  <b-button size="sm" :class="bookingStatusClass"> <i class="ncc-icon mr-1" :class="bookingTypeClass" aria-hidden="true"></i>{{ $t("booking.bookingStates." + booking.bookingStatus) }} </b-button>
</template>

<script>
import config from "@/config";

export default {
  props: {
    booking: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },

  computed: {
    bookingStatusClass: function () {
      if (this.booking.bookingStatus === config.bookingStates.canceled) {
        return "btn-gray";
      }
      if (this.booking.bookingStatus === config.bookingStates.reservation) {
        return "btn-skyblue";
      }

      //anything else, confirmed
      return "btn-blue";
    },
    bookingTypeClass: function () {
      if (this.booking.bookingType === config.bookingTypes.shortStay && this.booking.memberNumber === null) {
        return "ncc-aspirant-lid";
      }
      if (this.booking.bookingType === config.bookingTypes.shortStay && this.booking.memberNumber !== null) {
        return "ncc-passantenplaats";
      }
      if (this.booking.bookingType === config.bookingTypes.longStay) {
        return "ncc-seizoensplaats";
      }

      //anything else, stalling
      return "ncc-stalling";
    }
  }
};
</script>
