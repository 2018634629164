var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-autosuggest',{ref:"autosuggest",staticClass:"autosuggest",attrs:{"suggestions":_vm.suggestions,"input-props":{ class: 'autosuggest__input form-control', placeholder: _vm.placeholder, onInputChange: _vm.onChanged },"section-configs":{
    currentBookings: {
      limit: 6,
      label: 'Boekingen in deze periode',
      onSelected: (suggestion) => {
        _vm.onSelected(suggestion);
      }
    },
    otherBookings: {
      limit: 6,
      label: 'Overige boekingen',
      onSelected: (suggestion) => {
        _vm.onSelected(suggestion);
      }
    }
  }},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return [_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(suggestion.item.bookingNumber)),_c('br'),_c('booking-status',{attrs:{"booking":suggestion.item}})],1),_c('div',{staticClass:"col-5 pr-3"},[_vm._v(" "+_vm._s(suggestion.item.name)+" "),_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(suggestion.item.memberNumber))]),_c('br'),_c('small',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(suggestion.item.accommodation)+" ")]),(suggestion.item.guestCount > 0)?_c('small',{staticClass:"text-blue"},[_vm._v("| "+_vm._s(suggestion.item.guestCount)+" personen ")]):_vm._e()]),_c('div',{staticClass:"col-1"},[_vm._v(" "+_vm._s(suggestion.item.pitchNumber)+" ")]),_c('div',{staticClass:"col-3"},[_vm._v(" "+_vm._s(_vm._f("moment")(suggestion.item.startDate,"DD-MM-YY"))+" - "+_vm._s(_vm._f("moment")(suggestion.item.endDate,"DD-MM-YY"))),_c('br'),_c('small',{staticClass:"text-blue"},[_vm._v(_vm._s(suggestion.item.nightCount)+" nachten")])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }