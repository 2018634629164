import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/metrics";

export default {
  // dashboard(catalogId) {
  //   return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/dashboard`);
  // },
  getArrivals(catalogId, from, to) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/arrivals`, {
      params: {
        from: from,
        to: to
      }
    });
  },
  getDepartures(catalogId, from, to) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/departures`, {
      params: {
        from: from,
        to: to
      }
    });
  },
  getAvailability(catalogId, from, to, pitchType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/availability`, {
      params: {
        pitchType: pitchType,
        from: from,
        to: to
      }
    });
  },
  getOccupancy(catalogId, from, to, bookingType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/occupancy`, {
      params: {
        bookingType: bookingType,
        from: from,
        to: to
      }
    });
  },
  getBookings(catalogId, from, to, bookingType) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/bookings`, {
      params: {
        bookingType: bookingType,
        from: from,
        to: to
      }
    });
  },
  getGuests(catalogId, from, to) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/guests`, {
      params: {
        from: from,
        to: to
      }
    });
  },
  getOpenBalance(catalogId, from, to) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${catalogId}/openbalance`, {
      params: {
        from: from,
        to: to
      }
    });
  }
};
