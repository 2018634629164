<template>
  <vue-autosuggest
    ref="autosuggest"
    class="autosuggest"
    :suggestions="suggestions"
    :input-props="{ class: 'autosuggest__input form-control', placeholder: placeholder, onInputChange: onChanged }"
    :section-configs="{
      currentBookings: {
        limit: 6,
        label: 'Boekingen in deze periode',
        onSelected: (suggestion) => {
          onSelected(suggestion);
        }
      },
      otherBookings: {
        limit: 6,
        label: 'Overige boekingen',
        onSelected: (suggestion) => {
          onSelected(suggestion);
        }
      }
    }"
    @selected="onSelected"
  >
    <template slot-scope="{ suggestion }">
      <div class="row no-gutters">
        <div class="col-3">{{ suggestion.item.bookingNumber }}<br /><booking-status :booking="suggestion.item" /></div>
        <div class="col-5 pr-3">
          {{ suggestion.item.name }} <span class="badge badge-info">{{ suggestion.item.memberNumber }}</span>
          <br />
          <small class="text-blue"> {{ suggestion.item.accommodation }} </small>
          <small v-if="suggestion.item.guestCount > 0" class="text-blue">| {{ suggestion.item.guestCount }} personen </small>
        </div>
        <div class="col-1">
          {{ suggestion.item.pitchNumber }}
        </div>
        <div class="col-3">
          {{ suggestion.item.startDate | moment("DD-MM-YY") }} - {{ suggestion.item.endDate | moment("DD-MM-YY") }}<br />
          <small class="text-blue">{{ suggestion.item.nightCount }} nachten</small>
        </div>
      </div>
    </template>
  </vue-autosuggest>
</template>

<script>
import config from "@/config";
import { mapState, mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");
import BookingStatus from "@/components/common/Status/BookingStatus";

export default {
  components: { BookingStatus },

  props: {
    debounceMilliseconds: { type: Number, default: 500 },
    debounceStringLength: { type: Number, default: 2 },
    placeholder: { type: String, default: null }
  },

  data() {
    return {
      suggestions: [],
      timeout: null
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"]),
    ...mapGetters({
      isInRole: "Users/isInRole"
    })
  },

  methods: {
    onChanged(value) {
      if (value && value.length >= this.debounceStringLength) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          BookingRepository.getByQuery(
            this.currentCampsite.id,
            value,
            {
              from: this.isInRole("Supervisor") ? config.initialBookingSearchDateRange.startDate : config.defaultBookingSearchDateRange.startDate,
              to: this.isInRole("Supervisor") ? config.initialBookingSearchDateRange.endDate : config.defaultBookingSearchDateRange.endDate
            },
            0,
            1000
          ).then((bookings) => {
            let currentBookings = this.$_.orderBy(
              bookings.filter((b) => new Date() >= b.startDate && new Date() <= b.endDate),
              "startDate",
              "desc"
            );
            let otherBookings = this.$_.orderBy(
              bookings.filter((b) => new Date() < b.startDate || new Date() > b.endDate),
              "startDate",
              "desc"
            );
            this.suggestions = [
              { name: "currentBookings", data: currentBookings },
              { name: "otherBookings", data: otherBookings }
            ];
          });
        }, this.debounceMilliseconds);
      }
    },
    onSelected(suggestion) {
      if (suggestion.item !== undefined) {
        this.$emit("select", suggestion.item);
      }

      //clear input
      this.$refs.autosuggest.searchInput = "";

      //clear suggestions
      this.suggestions = [];
    }
  }
};
</script>
