<template>
  <div>
    <b-form-row>
      <b-col cols="3">Lidnummer</b-col>
      <b-col>
        <b-badge variant="info">{{ member.memberNumber }}</b-badge>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-1">
      <b-col cols="3">Naam</b-col>
      <b-col>{{ member.name }}</b-col>
      <b-col cols="1" class="text-right"><i class="fa fa-envelope" /></b-col>
      <b-col>{{ member.emailAddress }}</b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="3">Adres</b-col>
      <b-col>
        {{ member.address1 }} {{ member.address2 }}<br />
        {{ member.postcode }} {{ member.city }}
      </b-col>
      <b-col cols="1" class="text-right"><i class="fa fa-phone" /></b-col>
      <b-col>{{ member.phone1 }}<br />{{ member.phone2 }}</b-col>
    </b-form-row>
    <b-form-row class="mt-2">
      <b-col cols="3">Meterstand</b-col>
      <b-col>{{ member.electricityPosition }}</b-col>
      <b-col offset="1">
        <b-badge v-if="member.electricityMeterId" v-b-tooltip.hover variant="info" title="Meternummer">{{ member.electricityMeterId }}</b-badge>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-2">
      <b-col cols="3">Caravan</b-col>
      <b-col>{{ caravanManufacturerModel }}</b-col>
      <b-col offset="1">
        <b-badge v-if="member.registrationPlate" v-b-tooltip.hover variant="info" title="Kenteken">{{ member.registrationPlate }}</b-badge>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="3">Abonnementen</b-col>
      <b-col>
        <p v-if="member.subscriptions && member.subscriptions.length > 0">
          <span v-for="(subscription, index) in member.subscriptions" :key="index"> {{ subscription }}<span v-if="index + 1 < member.subscriptions.length">, </span> </span>
        </p>
        <span v-if="!member.subscriptions || member.subscriptions.length === 0">-</span>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    caravanManufacturerModel: function () {
      var manufacturerModel = `${this.member.manufacturer ?? ""} ${this.member.model ?? ""}`.trim();

      if (manufacturerModel.length) return manufacturerModel;

      return "Niet bekend";
    }
  }
};
</script>
