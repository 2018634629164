import moment from "moment";
import "moment/locale/nl";

import _ from "lodash";
Object.defineProperty(Vue.prototype, "$_", { value: _ });

//import "./filters.js";
import * as filters from "@/util/filters";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

//make sure moment is registered, so that it can be used in the views or computed properties
Vue.prototype.moment = moment;

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import VueFilters from "vue2-filters";
var Vue2FiltersConfig = {
  currency: {
    symbol: "€",
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  }
};
Vue.use(VueFilters, Vue2FiltersConfig);

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueAutosuggest from "vue-autosuggest";
Vue.use(VueAutosuggest);

import * as ModalDialogs from "vue-modal-dialogs";
Vue.use(ModalDialogs);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueMoment from "vue-moment";
Vue.use(VueMoment, { moment });

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

import { ValidationProvider, ValidationObserver, extend, setInteractionMode, localize } from "vee-validate";
import nl from "vee-validate/dist/locale/nl.json";
import { alpha_num, confirmed, email, max, min, required, double } from "vee-validate/dist/rules";

localize("nl", nl);
setInteractionMode("lazy");

extend("alpha_num", alpha_num);
extend("confirmed", confirmed);
extend("email", email);
extend("max", max);
extend("min", min);
extend("required", required);
extend("double", double);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_TRACKINGID,
  router,
  disabled: process.env.NODE_ENV != "production",
  debug: {
    enabled: false,
    sendHitTask: process.env.NODE_ENV === "production"
  }
});

import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  firstDayOfWeek: 2, // Monday
  masks: {
    title: "MMMM YYYY",
    weekdays: "WW",
    navMonths: "MMM",
    input: ["D-M", "D-M", "DD-MM-YYYY", "DD/MM/YYYY"],
    dayPopover: "L",
    data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"]
  },
  datePicker: {
    popover: {
      visibility: "click",
      keepVisibleOnInput: true
    }
  }
});

//SEE: https://github.com/RickStrahl/json.date-extensions
import "json.date-extensions";
JSON.useDateParser();

Vue.config.productionTip = false;

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount("#app");
