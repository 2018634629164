<template>
  <b-container id="app" fluid>
    <dialogs-wrapper />

    <router-view />
  </b-container>
</template>

<style lang="scss">
//== Lato font
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

//== Font Awesome
//set the relative path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

//== Bootstrap and included styles
@import "./style/style.scss";
</style>

<script>
import { mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
import Layout from "@/components/Layout.vue";

export default {
  watch: {
    $route() {
      this.clearAlert();
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "Alert/clear"
    })
  }
};
</script>
