<template>
  <validation-observer ref="addressForm">
    <b-form>
      <b-form-row>
        <b-form-group label="Alliantie" :label-cols="3" class="col-sm-12 mb-0">
          <b-form-select v-model="booking.allianceNumber" :options="alliances" text-field="name" value-field="allianceNumber" class="mb-3" name="allianceNumber" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Lidnummer" :label-cols="3" class="col-sm-12">
          <b-form-input v-model="booking.allianceMemberNumber" name="allianceMemberNumber" type="text" placeholder="Lidnummer alliantie" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <hr class="thin w-100" />
      </b-form-row>
      <b-form-row>
        <b-form-group label="Voornaam" :label-cols="6" class="col-sm-6">
          <ValidationProvider v-slot="v" name="Voornaam" rules="required">
            <b-form-input v-model="booking.bookingAddress.firstName" name="firstName" type="text" placeholder="Voornaam" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="Achternaam" :label-cols="4" class="col-sm-6">
          <ValidationProvider v-slot="v" name="Achternaam" rules="required">
            <b-form-input v-model="booking.bookingAddress.lastName" name="lastName" type="text" placeholder="Achternaam" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Straat" :label-cols="3" class="col-sm-12">
          <ValidationProvider v-slot="v" name="Straat" rules="required">
            <b-form-input v-model="booking.bookingAddress.address1" name="street" type="text" placeholder="Straat" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Toevoeging" :label-cols="3" class="col-sm-12">
          <ValidationProvider v-slot="v" name="Toevoeging" rules="max:20">
            <b-form-input v-model="booking.bookingAddress.address2" name="address2" type="text" placeholder="Toevoeging" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Postcode" :label-cols="6" class="col-sm-6">
          <ValidationProvider v-slot="v" name="Postcode" rules="required|min:4">
            <b-form-input v-model="booking.bookingAddress.postcode" name="zipcode" type="text" placeholder="Postcode" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="Plaats" :label-cols="3" class="col-sm-6">
          <ValidationProvider v-slot="v" name="Plaats" rules="required">
            <b-form-input v-model="booking.bookingAddress.city" name="city" type="text" placeholder="Plaats" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Land" :label-cols="3" class="col-sm-12 mb-0">
          <ValidationProvider v-slot="v" name="Land" rules="required|min:2|alpha_num">
            <b-form-select
              v-model="booking.bookingAddress.countryCode"
              :options="countries"
              text-field="name"
              value-field="twoLetterISORegionName"
              class="mb-3"
              name="countryCode"
              :state="getValidationState(v)"
            />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="E-mail" :label-cols="3" class="col-sm-12">
          <ValidationProvider v-slot="v" name="E-mail" rules="required|email">
            <b-form-input v-model="booking.bookingAddress.emailAddress" name="emailAddress" type="email" placeholder="E-mail" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Telefoon" :label-cols="3" class="col-sm-12">
          <ValidationProvider v-slot="v" name="Telefoon" rules="required|min:10">
            <b-form-input v-model="booking.bookingAddress.phone1" name="phone1" type="text" placeholder="" :state="getValidationState(v)" />
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
      </b-form-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapState } from "vuex";
import ValidationMixin from "@/mixins/validation-mixin";

export default {
  mixins: [ValidationMixin],

  props: {
    booking: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("Countries", ["countries"]),
    ...mapState("Alliances", ["alliances"])
  }

  // mounted() {
  //   AllianceRepository.getAlliances()
  //     .then((response) => {
  //       this.alliances = response;
  //     })
  //     .catch((error) => {
  //       this.$notify({
  //         group: "app",
  //         type: "error",
  //         title: this.$i18n.t("general_error_title"),
  //         text: error.response.data.message
  //       });
  //     });
  // }
};
</script>
