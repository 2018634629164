<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <button style="min-width: 2.5em" class="btn btn-decrement btn-outline-primary" type="button" @click.prevent="updateSpinnerValue(spinnerValue - step)">
        <strong>-</strong>
      </button>
    </div>
    <input
      style="text-align: center"
      class="form-control"
      type="number"
      :value="spinnerValue"
      :min="min"
      :max="max"
      debounce="500"
      @keypress="validateInput"
      @input="updateSpinnerValue($event.target.value)"
    />
    <div class="input-group-append">
      <button style="min-width: 2.5em" class="btn btn-increment btn-outline-primary" type="button" @click.prevent="updateSpinnerValue(spinnerValue + step)">
        <strong>+</strong>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 10,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    },
    integer: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      spinnerValue: this.value
    };
  },
  watch: {
    // SEE: https://forum.vuejs.org/t/data-properties-based-on-props-are-not-re-evaluated-when-props-change/21085
    value: function (val) {
      this.spinnerValue = val;
    }
  },
  methods: {
    updateSpinnerValue(value) {
      if (value <= this.min) {
        this.spinnerValue = parseInt(this.min);
      }
      if (value >= this.max) {
        this.spinnerValue = parseInt(this.max);
      }
      if (value <= this.max && value >= this.min) {
        this.$emit("input", value, this.spinnerValue);

        this.spinnerValue = value;
        // support .sync
        this.$emit("update:value", value);
      }
    },
    isInteger(evt) {
      evt = evt || window.event;

      let key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
      const regex = /[0-9]/;
      if (!regex.test(key)) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault();
      }
    },
    isNumber(evt) {
      evt = evt || window.event;

      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateInput(evt) {
      if (this.integerOnly === true) {
        this.isInteger(evt);
      } else {
        this.isNumber(evt);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
