<template>
  <div>
    <b-dropdown
      right
      variant="success"
      :disabled="!(currentCampsite.isShortStayAvailable || currentCampsite.isLongStayAvailable || currentCampsite.isShortStorageStayAvailable || currentCampsite.isLongStorageStayAvailable)"
    >
      <template slot="button-content"> <i class="fas fa-plus mr-1" /> Nieuwe boeking </template>
      <b-dropdown-item v-if="currentCampsite.isShortStayAvailable" @click="createBooking(config.bookingTypes.shortStay)">Kort verblijf (passantenplaats)</b-dropdown-item>
      <b-dropdown-item v-if="currentCampsite.isLongStayAvailable" @click="createBooking(config.bookingTypes.longStay)">Lang verblijf (seizoensplaats)</b-dropdown-item>
      <b-dropdown-item v-if="currentCampsite.isShortStorageAvailable" @click="createBooking(config.bookingTypes.shortStorage)">Kort stallen (parkeerplaats)</b-dropdown-item>
      <b-dropdown-item v-if="currentCampsite.isLongStorageAvailable" @click="createBooking(config.bookingTypes.longStorage)">Lang stallen (stalling)</b-dropdown-item>
    </b-dropdown>
    <create-booking-dialog :booking-type="bookingType" />
  </div>
</template>

<script>
import config from "@/config";
import { mapState } from "vuex";

import CreateBookingDialog from "@/components/common/Dialogs/CreateBookingDialog";

export default {
  components: { CreateBookingDialog },

  data() {
    return {
      bookingType: config.bookingTypes.shortStay,
      config: config
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  methods: {
    createBooking(bookingType) {
      this.bookingType = bookingType;
      this.$bvModal.show("create-booking");
    }
  }
};
</script>
