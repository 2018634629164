import moment from "moment";
import _ from "lodash";
import { DateHelpers } from "@/helpers";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import config from "@/config";
const BookingRepository = RepositoryFactory.get("BookingRepository");

export default {
  namespaced: true,
  state: {
    booking: null,
    selectedStay: null,
    isEditing: false
  },
  getters: {
    // Booking
    getStays: (state) => {
      const booking = state.booking;
      if (booking == null || !booking.stays) return [];

      let stays = _.sortBy(booking.stays, function (stay) {
        return moment(stay.endDate).format("YYYYMMDD");
      });

      let seasonStayIndex = _.findIndex(stays, { id: booking.id });
      if (seasonStayIndex !== 0) {
        let seasonStay = stays.splice(seasonStayIndex, 1);
        stays.unshift(seasonStay[0]);
      }

      return stays;
    },
    getStay: (state) => (stayId) => {
      if (state.booking && stayId) {
        return _.find(state.booking.stays, function (s) {
          return s.id == stayId;
        });
      }
    },
    isCancellationAllowed: (state) => {
      if (state.booking) {
        return (
          state.booking.bookingStatus !== config.bookingStates.confirmed &&
          state.booking.bookingStatus !== config.bookingStates.canceled &&
          state.booking.paymentStatus !== config.paymentStates.completed
        );
      }
    },
    isReservationAllowed: (state) => {
      if (state.booking) {
        return state.booking.bookingStatus === config.bookingStates.canceled;
      }
    },
    isDeletionAllowed: (state) => {
      if (state.booking) {
        return state.booking.bookingStatus !== config.bookingStates.confirmed && state.booking.paymentStatus !== config.paymentStates.completed;
      }
    },
    isCalculationAllowed: (state) => {
      if (state.booking) {
        return state.booking.bookingStatus !== config.bookingStates.canceled && state.booking.paymentStatus !== config.paymentStates.completed;
      }
    },
    isEditing: (state) => state.isEditing,
    //NOTE: (mho) isEditingAllowed replaces canBeEdited. If !isEditingAllowed, then show popup to alert the user that editing can have financial consequences
    isEditingAllowed: (state) => {
      if (state.booking) {
        return state.booking.bookingStatus !== config.bookingStates.canceled;
      }
      return false;
    },
    canBeEdited: (state) => {
      if (state.booking) {
        return state.booking.checkOutDate === null && state.booking.paymentStatus !== config.paymentStates.completed && state.booking.bookingStatus !== config.bookingStates.canceled;
      }
      return false;
    },
    isCheckedIn: (state) => {
      if (state.booking) {
        return state.booking.checkInDate !== null;
      }
    },
    isCheckedOut: (state) => {
      if (state.booking) {
        return state.booking.checkOutDate !== null;
      }
    },
    isMemberBooking: (state) => {
      if (state.booking) {
        return state.booking.memberId !== null;
      }
    },
    getCurrentStaysEndDate: (state, getters) => {
      if (state.booking) {
        const sortedStays = getters.getStays;
        return _.last(sortedStays).endDate;
      }
    },
    getBookingQueryType: (state, getters) => (stayId) => {
      if (state.booking.bookingType === "ShortStay") {
        if (getters.isMemberBooking) {
          return "ShortStay";
        }
        return "ShortStayNonMembers";
      }

      if (state.booking.id !== stayId) {
        return "Stay";
      }

      return "LongStay";
    },

    // Stay
    getStayAccommodation: (state, getters) => (stayId) => {
      let stay = getters.getStay(stayId);

      if (stay && stay.accommodations.length > 0) {
        return stay.accommodations[0];
      }
    },
    hasPayments: (state, getters) => (stayId) => {
      let stay = getters.getStay(stayId);
      if (stay) {
        return stay.remainingTotal === 0 && stay.payments.length > 0;
      }
    },
    isStayPaymentPossible: (state, getters) => (stayId) => {
      let stay = getters.getStay(stayId);
      if (stay) {
        return stay.remainingTotal > 0 && state.booking.bookingStatus !== config.bookingStates.canceled;
      }
    },
    isStayDiscountPossible: (state, getters) => (stayId) => {
      let stay = getters.getStay(stayId);
      if (stay) {
        return state.booking.bookingStatus !== config.bookingStates.canceled;
      }
    },
    isStayRemovalAllowed: (state, getters) => (stayId) => {
      let stay = getters.getStay(stayId);

      if (stay) {
        return state.booking.id != stay.id && stay.payments.length == 0;
      }

      return false;
    },
    isStayCreationAllowed: (state) => {
      if (state.booking) {
        const lastStay = state.booking.stays
          .slice()
          .filter((s) => s.id !== state.booking.id)
          .sort((a, b) => b.endDate - a.endDate)[0];
        if (lastStay && state.booking.endDate > lastStay.endDate) return true;

        const staysHaveGaps = state.booking.stays
          .slice()
          .filter((s) => s.id !== state.booking.id)
          .sort((a, b) => a.endDate - b.endDate)
          .every((s, i, a) => i == 0 || s.startDate > a[i - 1].endDate);
        if (staysHaveGaps) return true;

        return false;
      }
      //let allowCreation = false;
      // if (state.booking) {
      //   return state.booking.stays
      //     .slice()
      //     .filter((s) => s.id !== state.booking.id)
      //     .sort((a, b) => a.endDate - b.endDate)
      //     .some((s, i, a) => {
      //       console.log(s.startDate, s.endDate, i, i > 0 && s.startDate > a[i - 1].endDate, s.endDate < state.booking.endDate, s);
      //       return (i > 0 && s.startDate > a[i - 1].endDate) || s.endDate < state.booking.endDate;
      //     });

      //return false;

      // if (state.booking.stays.length > 1) {
      //   return moment(state.booking.endDate).isAfter(getters.getCurrentStaysEndDate);
      // }
      // return true;
    },
    newStayDateRange: (state, getters) => {
      if (state.booking) {
        let start = null;
        let end = null;

        if (state.booking.stays.length === 1) {
          start = moment(state.booking.startDate).startOf("day");
          end = moment(start).endOf("month").startOf("day").add({ days: 1 });
        } else {
          const currentStaysEndDate = getters.getCurrentStaysEndDate;
          start = moment(currentStaysEndDate).add({ days: 1 }).startOf("day").subtract({ days: 1 });
          end = moment(start).endOf("month").startOf("day").add({ days: 1 });
        }

        if (moment(end).isAfter(state.booking.endDate, "day")) {
          end = state.booking.endDate;
        }

        return { start: moment(start), end: moment(end) };
      }
    },
    getNumberOfGuests: (state) => (sku) => {
      const booking = state.booking;
      if (booking == null) return 0;

      return booking.guests.filter((guest) => sku === undefined || guest.sku === sku).length;
    },
    getNumberOfGuestDays: (state) => (sku) => {
      const booking = state.booking;
      if (booking == null) return 0;

      let count = 0;

      booking.guests
        .filter((guest) => sku === undefined || guest.sku === sku)
        .forEach((guest) => {
          count += DateHelpers.getDaysCount(guest.dates);
        });

      return count;
    },
    getAllowedStayDateRange: (state) => {
      const stay = state.selectedStay;

      if (stay) {
        return {
          start: stay.startDate,
          end: moment(stay.endDate).subtract({ days: 1 }).toDate()
        };
      }
    },
    hasBookingAddress: (state) => {
      const booking = state.booking;
      if (booking == null) return false;

      return booking.bookingAddress !== null;
    }
  },
  mutations: {
    setBooking: (state, booking) => {
      state.booking = booking;
    },
    setSelectedStay: (state, stay) => {
      state.selectedStay = stay;
    },
    toggleIsEditing: (state) => {
      state.isEditing = !state.isEditing;
    }
  },
  actions: {
    fetchBooking: async (context, { id, stayId }) => {
      if (id) {
        await BookingRepository.get(context.rootState.Campsites.currentCampsite.id, id).then((booking) => {
          context.commit("setBooking", booking);
          if (stayId) {
            context.commit("setSelectedStay", _.find(booking.stays, ["id", stayId]));
          } else {
            context.commit("setSelectedStay", _.find(booking.stays, ["id", booking.id]));
          }
        });
      }
    }
  }
};
