import Vue from "vue";
import Vuex from "vuex";

//import store modules
import Alert from "@/store/modules/Alert";
import Authentication from "@/store/modules/Authentication";
import Campsites from "@/store/modules/Campsites";
import Countries from "@/store/modules/Countries";
import Alliances from "@/store/modules/Alliances";
import PaymentMethods from "@/store/modules/PaymentMethods";
import CurrentBooking from "@/store/modules/CurrentBooking";
import Users from "@/store/modules/Users";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Alert,
    Authentication,
    Campsites,
    Countries,
    Alliances,
    CurrentBooking,
    PaymentMethods,
    Users
  },
  strict: debug
});
