import { api as AxiosRepository } from "./AxiosRepository";
const apiVersionBaseUrl = "/v1/members";

export default {
  getByQuery(query) {
    return AxiosRepository.get(apiVersionBaseUrl, {
      params: { query }
    }).then((response) => response.data);
  },
  getById(id) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${id}`);
  },
  getSubscription(id, productSku) {
    return AxiosRepository.get(`${apiVersionBaseUrl}/${id}/hasSubscriptionFor/${productSku}`);
  }
};
