import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { setToken, clearToken } from "@/services/UserTokenService";
import router from "@/router";
import i18n from "@/i18n";

const AuthenticationRepository = RepositoryFactory.get("AuthenticationRepository");

export default {
  namespaced: true,
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    expiresAt: localStorage.getItem("tokenExpiresAt") || ""
  },
  getters: {
    isAuthenticated: (state) => !!state.token
  },
  mutations: {
    auth_request(state) {
      state.status = { loggingIn: true };
    },
    auth_success(state, token, refreshToken, expiresAt) {
      state.status = { loggedIn: true };
      state.token = token;
      state.refreshToken = refreshToken;
      state.expiresAt = expiresAt;
    },
    auth_error(state) {
      state.status = {};
      state.token = "";
      state.refreshToken = "";
      state.expiresAt = "";
    },
    logout(state) {
      state.status = {};
      state.token = "";
      state.refreshToken = "";
      state.expiresAt = "";
    }
  },
  actions: {
    login({ dispatch, commit }, loginData) {
      commit("auth_request");

      AuthenticationRepository.login(loginData.username, loginData.password)
        .then((response) => {
          const token = response.data.access_token;

          setToken(token, response.data.refresh_token, response.data.expires_in);

          commit("auth_success", token);

          router.push("/");
        })
        .catch((error) => {
          commit("auth_error");
          clearToken();
          dispatch("Alert/error", error.response.status === 401 ? i18n.t("user.invalid_login") : i18n.t("general_error_title"), { root: true });
        });
    },
    logout({ commit }) {
      clearToken();
      commit("logout");
    }
  }
};
