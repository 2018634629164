<template>
  <b-row class="p-4 bg-light sticky-top">
    <b-col cols="4" xl="6">
      <b-input-group>
        <auto-suggest-booking placeholder="Zoek op nummer, naam, reservering of campingplaats" @select="$router.push({ name: 'booking-detail', params: { id: $event.id } })" />
        <b-input-group-append>
          <span class="input-group-text"><i class="fas fa-search" /></span>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col cols="4" xl="3">
      <create-booking-button />
    </b-col>
    <b-col cols="4" xl="3" class="d-xl-block">
      <camping-selector />
    </b-col>
  </b-row>
</template>

<script>
import AutoSuggestBooking from "@/components/common/AutoSuggest/AutoSuggestBooking";
import CampingSelector from "./CampingSelector.vue";
import CreateBookingButton from "@/components/common/CreateBookingButton";

export default {
  components: { CreateBookingButton, AutoSuggestBooking, CampingSelector }
};
</script>
