<template>
  <router-link :to="{ name: 'profile' }">
    <i class="fas fa-4x fa-user-circle align-middle mr-2" style="color: #fff"></i>
    <span class="username ml-2 align-middle">{{ currentUser.name }}</span>
  </router-link>
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";

.username {
  color: $white;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Users", ["currentUser"])
  }
};
</script>
