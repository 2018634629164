var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-autosuggest',{ref:"autosuggest",staticClass:"autosuggest",attrs:{"suggestions":_vm.suggestions,"input-props":{ class: 'autosuggest__input form-control', placeholder: _vm.placeholder, onInputChange: _vm.onChanged },"section-configs":{
    members: {
      limit: 12,
      label: 'Leden',
      onSelected: (suggestion) => {
        _vm.onSelected(suggestion);
      }
    }
  }},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return [_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col pr-3"},[_c('strong',[_vm._v(_vm._s(suggestion.item.name))]),_vm._v(" "),_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(suggestion.item.memberNumber))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s([suggestion.item.address1, suggestion.item.address2, suggestion.item.postcode, suggestion.item.city].filter((x) => x).join()))])]),_c('div',{staticClass:"col"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(suggestion.item.emailaddress))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(suggestion.item.phone))])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }