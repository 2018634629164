<template>
  <b-dropdown v-if="campsites.length" id="campsite-selector" block right class="float-right" :text="currentCampsite.name" variant="light">
    <b-dropdown-item v-for="campsite in campsites" :key="campsite.id" @click="selectCampsite(campsite)">
      {{ campsite.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("Campsites", ["currentCampsite", "campsites"])
    // otherCampsites: function() {
    //   return this.campsites.filter(c => {
    //     return this.currentCampsite.id !== c.id;
    //   });
    // }
  },
  methods: {
    ...mapMutations("Campsites", ["setCurrentCampsite"]),
    selectCampsite(campsite) {
      this.setCurrentCampsite(campsite);
      if (this.$route.name !== "dashboard") {
        this.$router.push({ name: "dashboard" });
      }
    }
  }
};
</script>
