import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import _ from "lodash";

const AllianceRepository = RepositoryFactory.get("AllianceRepository");

export default {
  namespaced: true,
  state: {
    alliances: []
  },
  getters: {},
  mutations: {
    setAlliances(state, alliances) {
      state.alliances = _.sortBy(alliances, ["name"]);
    }
  },
  actions: {
    async fetchAlliances(context) {
      await AllianceRepository.getAlliances().then((alliances) => {
        context.commit("setAlliances", alliances);
      });
    }
  }
};
